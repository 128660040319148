import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelezioneComponent } from './components/selezione/selezione.component';
import { IscrSezAComponent } from './components/iscr-sez-a/iscr-sez-a.component';
import { IscrSezBComponent } from './components/iscr-sez-b/iscr-sez-b.component';
import { IscrSezAPsiComponent } from './components/iscr-sez-a-psi/iscr-sez-a-psi.component';
import { DropdownResolver, BozzaResolver, PraticaResolver } from './service/resolver.service';
import { PraticheSalvateComponent } from './components/pratiche-salvate/pratiche-salvate.component';
import { InviataGuard } from './auth/inviata.guard';
import { AccessoNegatoComponent } from './components/accesso-negato/accesso-negato.component';

const routes: Routes = [
  {
    path: '',
    component: SelezioneComponent
  },
  {
    path: 'iscrizione_sezione_a',
    component: IscrSezAComponent,
    data: { 'sezione': 'A' },
    resolve: { 'dropdown': DropdownResolver },
    canActivate: [InviataGuard]
  },
  {
    path: 'iscrizione_sezione_a/:bozzaId',
    component: IscrSezAComponent,
    data: { 'sezione': 'A' },
    resolve: {
      'dropdown': DropdownResolver,
      'bozza': BozzaResolver
    },
    canActivate: [InviataGuard]
  },
  {
    path: 'iscrizione_sezione_b',
    component: IscrSezBComponent,
    data: { 'sezione': 'B' },
    resolve: { 'dropdown': DropdownResolver },
    canActivate: [InviataGuard]
  },
  {
    path: 'iscrizione_sezione_b/:bozzaId',
    component: IscrSezBComponent,
    data: { 'sezione': 'B' },
    resolve: {
      'dropdown': DropdownResolver,
      'bozza': BozzaResolver
    },
    canActivate: [InviataGuard]
  },
  {
    path: 'iscrizione_sezione_ap',
    component: IscrSezAPsiComponent,
    data: { 'sezione': 'A' },
    resolve: { 'dropdown': DropdownResolver },
    canActivate: [InviataGuard]
  },
  {
    path: 'iscrizione_sezione_ap/:bozzaId',
    component: IscrSezAPsiComponent,
    data: { 'sezione': 'A' },
    resolve: {
      'dropdown': DropdownResolver,
      'bozza': BozzaResolver
    },
    canActivate: [InviataGuard]
  },
  {
    path: 'pratiche_salvate',
    component: PraticheSalvateComponent,
    resolve: {
      'pratiche': PraticaResolver
    }
  },
  {
    path: 'access-denied',
    component: AccessoNegatoComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
