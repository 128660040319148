<form [formGroup]="iscrizioneForm!" class="p-4 w-full bg-color">

  <h1 class="text-center">Albo degli psicologi {{ regioneLabel }}{{ extraTitle }}</h1>

  <p class="text-center my-2 mx-8">Il/la sottoscritto/a, consapevole delle sanzioni penali previste 
    dall’art. 76 D.P.R. 445/2000 in caso di falsità in atti e dichiarazioni mendaci, 
    dichiara sotto la propria responsabilità la veridicità delle informazioni fornite</p>
    
  <p-stepper orientation="vertical">

    <!-- ANAGRAFICA [linear]="true" -->
    <p-stepperPanel formGroupName="anagrafica">
      <ng-template id="anagrafica" pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('anagrafica')!!.invalid ? 'invalid' : 'valid'"
          class="anagrafica bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('anagrafica')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-user"></i>
            </span>
            <span class="text-xl font-bold">ANAGRAFICA</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">

            <div class="flex flex-wrap">

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="sezioneAlbo" />
                <label for="sezioneAlbo">Sezione Albo</label>
              </p-floatLabel>

            </div>

            <div class="flex flex-wrap">

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="nome" />
                <label for="nome">Nome*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="cognome" />
                <label for="cognome">Cognome*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="codiceFiscale" maxlength="16" />
                <label for="codiceFiscale">Codice Fiscale*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-dropdown
                  formControlName="sesso"
                  [options]="sessoOptions"
                  optionLabel="name" />
                <label for="sesso">Sesso*</label>
              </p-floatLabel>

            </div>

            <div class="flex flex-wrap">

              <p-floatLabel class="m-3 md:m-4">
                <p-calendar formControlName="dataNascita" dateFormat="dd/mm/yy" />
                <label for="dataNascita">Data Nascita*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-autoComplete
                  appendTo="body"
                  formControlName="comuneNascita"
                  [suggestions]="filtrati"
                  (completeMethod)="filtro($event, 'comuni')"
                  field="nome"
                  [minLength]="3"
                  [showClear]="true" />
                <label for="comuneNascita">Comune Nascita*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-autoComplete
                  appendTo="body"
                  formControlName="provinciaNascita"
                  [suggestions]="filtrati"
                  (completeMethod)="filtro($event, 'province')"
                  field="nome"
                  [minLength]="2"
                  [showClear]="true" />
                <label for="provinciaNascita">Provincia Nascita*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-autoComplete
                  appendTo="body"
                  formControlName="cittadinanza"
                  [suggestions]="filtrati"
                  (completeMethod)="filtro($event, 'cittadinanza')"
                  field="nazionalita"
                  [minLength]="2"
                  [showClear]="true" />
                <label for="cittadinanza">Cittadinanza*</label>
              </p-floatLabel>

            </div>

            @if(isNotCittadinanzaItaliana()){
              <p @fadeInOut class="m-3 md:m-4 w-11">{{filePermessoSoggiorno.descrizione}}</p>
              <div @fadeInOut class="card mb-4 md:mb-2 flex justify-content-start align-items-center">
                <p-fileUpload
                  #fileUpload
                  class="ml-4 mr-4"
                  mode="basic"
                  name="demo[]"
                  chooseIcon="pi pi-upload"
                  customUpload="true"
                  accept="application/pdf"
                  maxFileSize="10000000"
                  (uploadHandler)="onBasicUploadAuto($event, filePermessoSoggiorno.tipo, fileUpload)"
                  [auto]="true"
                  chooseLabel="{{filePermessoSoggiorno.chooseLabel}}"
                  [ngStyle]="{'background-color': filePermessoSoggiorno.nomeFile !== '' ? '#6094C0' : '#D84347', 'border-radius': '5px'}"
                />
                @if(filePermessoSoggiorno.nomeFile !== ""){
                  <i (click)="visualizzaAllegato(filePermessoSoggiorno)" class="text-2xl pi pi-eye cursor-pointer"></i>
                }
                <p class="m-3">File caricato: {{filePermessoSoggiorno.nomeFile}}</p>
              </div>
            }

          </div>
        </div>
        <div class="flex py-4">
          <p-button
            class="avanti"
            label="Avanti"
            (onClick)="nextCallback.emit(); scrollToElement('abitazione')"
            [disabled]="iscrizioneForm!.get('anagrafica')?.invalid" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- RESIDENZA E DOMICILIO -->
    <p-stepperPanel formGroupName="abitazione">
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('abitazione')!!.invalid || !checkAbitazionePuglia() ? 'invalid' : 'valid'"
          class="abitazione bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('abitazione')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-home"></i>
            </span>
            <span class="text-xl font-bold">ABITAZIONE</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column flex-wrap justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">

            <div class="font-bold text-xl mx-4 my-3">
              <p>RESIDENZA</p>
            </div>

            <div class="flex flex-wrap">

              <p-floatLabel class="m-3 md:m-4">
                <p-autoComplete
                  appendTo="body"
                  formControlName="residenzaComune"
                  [suggestions]="filtrati"
                  (completeMethod)="filtro($event, 'comuni')"
                  field="nome"
                  [minLength]="3"
                  [showClear]="true" />
                <label for="residenzaComune">Comune*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-autoComplete
                  appendTo="body"
                  formControlName="residenzaProvincia"
                  [suggestions]="filtrati"
                  (completeMethod)="filtro($event, 'province')"
                  field="nome"
                  [minLength]="2"
                  [showClear]="true" />
                <label for="residenzaProvincia">Provincia*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="residenzaIndirizzo" />
                <label for="residenzaIndirizzo">Indirizzo*</label>
              </p-floatLabel>
              
              @if(regioneLabel === 'Puglia'){
                  <p-floatLabel class="m-3 md:m-4">
                    <input type="text" pInputText formControlName="residenzaFrazione" />
                    <label for="residenzaFrazione">Frazione</label>
                  </p-floatLabel>
              }
              
              @if(regioneLabel === 'Piemonte'){
                  <p-floatLabel class="m-3 md:m-4">
                    <input type="text" pInputText formControlName="residenzaCitofono" />
                    <label for="residenzaCitofono">Nome sul campanello o buca</label>
                  </p-floatLabel>
              }

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="residenzaCap" />
                <label for="residenzaCap">CAP*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-autoComplete
                  appendTo="body"
                  formControlName="residenzaStato"
                  [suggestions]="filtrati"
                  (completeMethod)="filtro($event, 'nazioni')"
                  field="nome"
                  [minLength]="2"
                  [showClear]="true" />
                <label for="residenzaStato">Stato*</label>
              </p-floatLabel>

            </div>
            @if(regioneLabel === 'Puglia'){
              <div class="flex font-bold align-items-center m-3 mb-1">
                <p class="line-height-2">
                  Attenzione!<br/>
                  <em>La delibera n. 29/2010 del Consiglio Nazionale ha introdotto l'esigenza di un collegamento
                    territoriale tra l'iscritto e l'Ordine Regionale di appartenenza, prevedendo la necessità di
                    avere la residenza o il domicilio professionale nel territorio regionale di riferimento. Nel
                    caso in cui, pertanto, non fosse residente in Puglia, La invitiamo ad indicarci almeno un
                    domicilio in questa Regione.</em>
                </p>
              </div>
            }
            

            <div class="flex align-items-center m-3 md:m-4">
              <p-checkbox
                formControlName="domicilioFlag"
                [binary]="true"
                inputId="domicilioFlag"
                class="mr-2" />
              <label for="domicilioFlag">Domicilio diverso da residenza</label>
            </div>

            @if (iscrizioneForm!.get('abitazione.domicilioFlag')?.value) {

              <div @fadeInOut style="max-width: 70rem;">

                <div class="font-bold text-xl mx-4 my-3">
                  <p>DOMICILIO</p>
                </div>

                <div class="flex flex-wrap">

                  <p-floatLabel class="m-3 md:m-4">
                    <p-autoComplete
                      appendTo="body"
                      formControlName="domicilioComune"
                      [suggestions]="filtrati"
                      (completeMethod)="filtro($event, 'comuni')"
                      field="nome"
                      [minLength]="3"
                      [showClear]="true" />
                    <label for="domicilioComune">Comune*</label>
                  </p-floatLabel>

                  <p-floatLabel class="m-3 md:m-4">
                    <p-autoComplete
                      appendTo="body"
                      formControlName="domicilioProvincia"
                      [suggestions]="filtrati"
                      (completeMethod)="filtro($event, 'province')"
                      field="nome"
                      [minLength]="2"
                      [showClear]="true" />
                    <label for="domicilioProvincia">Provincia*</label>
                  </p-floatLabel>

                  <p-floatLabel class="m-3 md:m-4">
                    <input type="text" pInputText formControlName="domicilioIndirizzo" />
                    <label for="domicilioIndirizzo">Indirizzo*</label>
                  </p-floatLabel>

                  @if(regioneLabel === 'Puglia'){
                      <p-floatLabel class="m-3 md:m-4">
                          <input type="text" pInputText formControlName="domicilioFrazione" />
                          <label for="domicilioFrazione">Frazione</label>
                      </p-floatLabel>
                  }

                  @if(regioneLabel === 'Piemonte'){
                      <p-floatLabel class="m-3 md:m-4">
                        <input type="text" pInputText formControlName="domicilioCitofono" />
                        <label for="domicilioCitofono">Nome sul campanello o buca</label>
                      </p-floatLabel>
                  }

                  <p-floatLabel class="m-3 md:m-4">
                    <input type="text" pInputText formControlName="domicilioCap" />
                    <label for="domicilioCap">CAP*</label>
                  </p-floatLabel>

                  <p-floatLabel class="m-3 md:m-4">
                    <p-autoComplete
                      appendTo="body"
                      formControlName="domicilioStato"
                      [suggestions]="filtrati"
                      (completeMethod)="filtro($event, 'nazioni')"
                      field="nome"
                      [minLength]="2"
                      [showClear]="true" />
                    <label for="domicilioStato">Stato*</label>
                  </p-floatLabel>

                </div>
              </div>
            }

            @if(regioneLabel === 'Piemonte' || regioneLabel === 'Puglia'){
              <p class="ml-3 mt-3 md:ml-4 md:mt-4">Desidero ricevere la corrispondenza presso:</p>
              <div class="flex align-items-center m-3 md:m-4">
                @for(posta of corrispondenza; track $index){
                  <div class="field-checkbox mr-2">
                    <p-radioButton
                      [disabled]="!iscrizioneForm!.get('abitazione.domicilioFlag')?.value"
                      [inputId]="posta.value"
                      [value]="posta.value"
                      formControlName="corrispondenzaPostale" />
                    <label [for]="posta.value" class="ml-2" [innerHTML]="posta.name"></label>
                  </div>
                }
              </div>
            }

          </div>
        </div>
        <div class="flex py-4 gap-2">
          <p-button
            label="Indetro"
            severity="secondary"
            (onClick)="prevCallback.emit(); scrollToElement('anagrafica')" />
          <p-button
            class="avanti"
            label="Avanti"
            (onClick)="nextCallback.emit(); scrollToElement('documento')"
            [disabled]="iscrizioneForm!.get('abitazione')?.invalid || !checkAbitazionePuglia()" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- DOCUMENTO-->
    <p-stepperPanel formGroupName="documento">
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('documento')!!.invalid ? 'invalid' : 'valid'"
          class="documento bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('documento')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-id-card"></i>
            </span>
            <span class="text-xl font-bold">DOCUMENTO</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-wrap justify-content-start align-items-center font-medium w-11 pt-3 md:p-4 md:pt-5">

            <p-floatLabel class="m-3 md:m-4">
              <p-dropdown
                formControlName="tipoDocumento"
                [options]="documentiIdentita"
                optionLabel="descrizione" />
              <label for="tipoDocumento">Documento*</label>
            </p-floatLabel>

            <p-floatLabel class="m-3 md:m-4">
              <input type="text" pInputText formControlName="numeroDocumento" />
              <label for="numeroDocumento">Numero*</label>
            </p-floatLabel>

            <p-floatLabel class="m-3 md:m-4">
              <p-calendar formControlName="dataRilascioDocumento" dateFormat="dd/mm/yy" />
              <label for="dataRilascioDocumento">Data Rilascio*</label>
            </p-floatLabel>

            <p-floatLabel class="m-3 md:m-4">
              <input type="text" pInputText formControlName="rilasciatoDa" />
              <label for="rilasciatoDa">Rilasciato da*</label>
            </p-floatLabel>

          </div>
        </div>
        <div class="flex py-4 gap-2">
          <p-button
            label="Indetro"
            severity="secondary"
            (onClick)="prevCallback.emit(); scrollToElement('abitazione')" />
          <p-button
            class="avanti"
            label="Avanti"
            (onClick)="nextCallback.emit(); scrollToElement('contatti')"
            [disabled]="iscrizioneForm!.get('documento')?.invalid" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- CONTATTI -->
    <p-stepperPanel formGroupName="contatti">
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('contatti')!!.invalid ? 'invalid' : 'valid'"
          class="contatti bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('contatti')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-phone"></i>
            </span>
            <span class="text-xl font-bold">CONTATTI</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">

            <div class="flex flex-wrap justify-content-start align-items-center mb-3">

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="email" />
                <label for="email">Email*</label>
              </p-floatLabel>

              <div class="relative m-3 md:m-4">
                <p-floatLabel>
                  <input type="text" pInputText formControlName="pec" />
                  <label for="pec">PEC</label>
                </p-floatLabel>
              </div>

            </div>

            <div class="flex flex-wrap justify-content-start align-items-center mb-3">

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="telefono" />
                <label for="telefono">Telefono</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="cellulare" />
                <label for="cellulare">Cellulare*</label>
              </p-floatLabel>

              @if(regioneLabel === 'Puglia'){
                <p-floatLabel class="m-3 md:m-4">
                  <input type="text" pInputText formControlName="fax" />
                  <label for="fax">Fax</label>
                </p-floatLabel>
              }

            </div>

            <div class="font-italic font-bold px-4">
              <p class="line-height-2 mb-4" style="max-width: 70rem;">
                Sarà possibile attivare la PEC, offerta gratuitamente dall'Ordine,
                una settimana dopo aver ricevuto la comunicazione di avvenuta iscrizione
                (NON dall'invio della domanda di iscrizione)
                accedendo all'Area Riservata del CNOP: https://areariservata.psy.it/cgi-bin/areariservata/ar.cgi
              </p>
              <p class="line-height-2 mb-4" style="max-width: 70rem;">
                Entro 30 giorni dall'AVVENUTA ISCRIZIONE per coloro che non avranno
                attivato e comunicato la propria PEC all'Ordine verrà avviato il
                procedimento amministrativo di sospensione dall'esercizio della
                professione ai sensi dell'art.16, D.L.185/2008, modificato dal
                D.L. n.76/2020.
              </p>
              <p class="line-height-2 mb-4">
                La PEC sarà resa pubblica sul sito Web dell'Ordine
              </p>

            </div>

          </div>
        </div>
        <div class="flex py-4 gap-2">
          <p-button
            label="Indetro"
            severity="secondary"
            (onClick)="prevCallback.emit(); scrollToElement('documento')" />
          <p-button
            class="avanti"
            label="Avanti"
            (onClick)="nextCallback.emit(); scrollToElement('laurea_abilitazione')"
            [disabled]="iscrizioneForm!.get('contatti')?.invalid" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- LAUREA E ABILITAZIONE -->
    <p-stepperPanel formGroupName="laurea_abilitazione">
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('laurea_abilitazione')!!.invalid ? 'invalid' : 'valid'"
          class="laurea_abilitazione bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('laurea_abilitazione')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-graduation-cap"></i>
            </span>
            <span class="text-xl font-bold">LAUREA E ABILITAZIONE</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column flex-wrap justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">

            <div class="mx-4 my-3">
              <p class="font-bold text-xl mb-2">LAUREA</p>
              @if(regioneLabel === 'Puglia'){
                <p>Laurea Triennale (classe L 34 D.M. 509/1999 - c.d. “nuovo ordinamento”)
                  - Scienze e tecniche psicologiche</p>
              }
              @if(regioneLabel === 'Piemonte'){
                <p>Laurea triennale in Scienze e Tecniche Psicologiche</p>
              }
            </div>

            <div class="flex flex-wrap">

              <p-floatLabel class="m-3 md:m-4">
                <p-dropdown
                  formControlName="titolo"
                  [options]="titoli"
                  optionLabel="nome" />
                <label for="titolo">Titolo Laurea*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-dropdown
                  formControlName="univLaurea"
                  [options]="universita"
                  optionLabel="nome" />
                <label for="univLaurea">Università*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <input type="text" pInputText formControlName="corsoLaurea" />
                <label for="corsoLaurea">Corso di Laurea*</label>
              </p-floatLabel>

              <p-floatLabel class="m-3 md:m-4">
                <p-calendar formControlName="laureaData" dateFormat="dd/mm/yy" />
                <label for="laureaData">Data Laurea*</label>
              </p-floatLabel>

            </div>

            <div style="max-width: 70rem;">

              <div class="mx-4 my-3">
                <p class="font-bold text-xl mb-2">ABILITAZIONE</p>
                @if(regioneLabel === 'Puglia'){
                  <p>Laurea in Psicologia (classe L 24 D.M. 270/2004 - c.d. 
                    “nuovissimo ordinamento”) - Scienze e tecniche psicologiche</p>
                }
                @if(regioneLabel === 'Piemonte'){
                  <p>Prova Tecnico Valutativa finalizzata all’abilitazione professionale di 
                    “Dottore in tecniche psicologiche”</p>
                }
              </div>

              <div class="flex flex-wrap">

                <p-floatLabel class="m-3 md:m-4">
                  <p-dropdown
                    formControlName="universitaAbilitazione"
                    [options]="universita"
                    optionLabel="nome" />
                  <label for="universitaAbilitazione">Università Abilitazione</label>
                </p-floatLabel>

                <p-floatLabel class="m-3 md:m-4">
                  <input type="text" pInputText formControlName="abilitazioneSessione" />
                  <label for="abilitazioneSessione">Sessione Abilitazione</label>
                </p-floatLabel>

                <p-floatLabel class="m-3 md:m-4">
                  <p-calendar formControlName="abilitazioneData" dateFormat="dd/mm/yy" />
                  <label for="abilitazioneData">Data Abilitazione</label>
                </p-floatLabel>

              </div>

            </div>

          </div>
        </div>
        <div class="flex py-4 gap-2">
          <p-button
            label="Indetro"
            severity="secondary"
            (onClick)="prevCallback.emit(); scrollToElement('contatti')" />
          <p-button
            class="avanti"
            label="Avanti"
            (onClick)="nextCallback.emit(); scrollToElement('dichiarazioni')"
            [disabled]="iscrizioneForm!.get('laurea_abilitazione')?.invalid" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- DICHIARAZIONI -->
    <p-stepperPanel formGroupName="dichiarazioni">
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('dichiarazioni')!!.invalid ? 'invalid' : 'valid'"
          class="dichiarazioni bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('dichiarazioni')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-pen-to-square"></i>
            </span>
            <span class="text-xl font-bold">DICHIARAZIONI</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">

            <div class="font-bold text-xl mx-4 my-3">
              <p>Il richiedente dichiara inoltre:</p>
            </div>

            <div class="flex align-items-center m-3 md:m-4">
              <p-checkbox
                formControlName="delittiFlag"
                [binary]="true"
                inputId="delittiFlag"
                class="mr-2" />
              <label for="delittiFlag">
                di <strong>NON AVER</strong> riportato condanne penali passate in giudicato
                per delitti che comportino l'interdizione dalla professione <strong>(obbligatorio)</strong>
              </label>
            </div>

            @if(iscrizioneForm!.get('dichiarazioni.delittiFlag')?.value){
              <p @fadeInOut class="mx-3 mb-3 text-sm">
                <em>Per verificare la veridicità di quanto dichiarato, l'Ordine è tenuto ad effettuare gli
                opportuni accertamenti presso il Casellario Giudiziale. Nel caso in cui sul certificato penale
                risultino annotazioni particolari la domanda di iscrizione potrebbe non essere accolta.</em></p>
            }

            <div class="flex align-items-center m-3 md:m-4 mb-2">
              <p-checkbox
                formControlName="altriConsigliFlag"
                [binary]="true"
                inputId="altriConsigliFlag"
                class="mr-2" />
              <label for="altriConsigliFlag">di <strong class="text-lg">NON AVER</strong> presentato domanda in
                altri Consigli Regionali o Provinciali <strong>(obbligatorio)</strong>
              </label>
            </div>

            @if(regioneLabel === 'Piemonte'){
              <div class="flex align-items-center m-3 md:m-4 mb-2">
                <p-checkbox
                  formControlName="iscrizioneAltroOrdineFlag"
                  [binary]="true"
                  inputId="iscrizioneAltroOrdineFlag"
                  class="mr-2" />
                <label for="iscrizioneAltroOrdineFlag">di <strong class="text-lg">ESSERE</strong> già stato iscritto in
                  un altro Ordine degli Psicologi
                </label>
              </div>

              @if(iscrizioneForm!.get('dichiarazioni.iscrizioneAltroOrdineFlag')?.value){
                <div @fadeInOut class="flex flex-wrap justify-content-start align-items-center mb-2">

                  <p-floatLabel class="m-3 md:m-4">
                    <p-autoComplete
                      appendTo="body"
                      formControlName="regioneAltroOrdine"
                      [suggestions]="filtrati"
                      field="nome"
                      (completeMethod)="filtro($event, 'ordini')"
                      [showClear]="true" />
                    <label for="regioneAltroOrdine">Regione*</label>
                  </p-floatLabel>
  
                  <p-floatLabel class="m-3 md:m-4">
                    <p-calendar formControlName="dataCancellazioneAltroOrdine" dateFormat="dd/mm/yy" />
                    <label for="dataCancellazioneAltroOrdine">Data Cancellazione*</label>
                  </p-floatLabel>
  
                </div>
              }
            }

            <div class="flex align-items-center m-3 md:m-4">
              <p-checkbox
                formControlName="dipendentePubblico"
                [binary]="true"
                inputId="dipendentePubblico"
                class="mr-2" />
              <label for="dipendentePubblico">di <strong class="text-lg">ESSERE</strong> Dipendente Pubblico</label>
            </div>

            @if(iscrizioneForm!.get('dichiarazioni.dipendentePubblico')?.value){
              <div @fadeInOut class="flex flex-wrap justify-content-start align-items-center mb-2">

                <p-floatLabel class="m-4">
                  <input type="text" pInputText formControlName="dipendentePubblicoPresso" />
                  <label for="dipendentePubblicoPresso">Presso l'Ente*</label>
                </p-floatLabel>

                <p-floatLabel class="m-4">
                  <input type="text" pInputText formControlName="dipendentePubblicoRuolo" />
                  <label for="dipendentePubblicoRuolo">in Qualità di</label>
                </p-floatLabel>

              </div>

              <div @fadeInOut class="ml-4 mt-3" style="max-width: 70rem;">

                <div>
                  <p class="font-bold mb-3">Ai sensi dell'art.8 della Legge n.56/1989 dichiaro:</p>
                  @for(auto of articolo82Options; track $index){
                    <div class="field-checkbox">
                      <p-radioButton
                        [inputId]="auto.value"
                        [value]="auto.value"
                        formControlName="articolo82" />
                      <label [for]="auto.value" class="ml-2" [innerHTML]="auto.name"></label>
                    </div>
                  }

                  @if(iscrizioneForm!.get('dichiarazioni.articolo82')?.value){
                    <p @fadeInOut class="my-4 w-11">{{fileAutorizzazione.descrizione}}</p>
                    <div @fadeInOut class="card mb-4 md:mb-2 flex justify-content-start align-items-center">
                      <p-fileUpload
                        #fileUpload
                        class="mr-4"
                        mode="basic"
                        name="demo[]"
                        chooseIcon="pi pi-upload"
                        customUpload="true"
                        accept="application/pdf"
                        maxFileSize="10000000"
                        (uploadHandler)="onBasicUploadAuto($event, fileAutorizzazione.tipo, fileUpload)"
                        [auto]="true"
                        chooseLabel="{{fileAutorizzazione.chooseLabel}}"
                        [ngStyle]="{'background-color': fileAutorizzazione.nomeFile !== '' ? '#6094C0' : '#D84347', 'border-radius': '5px'}"
                      />
                      @if(fileAutorizzazione.nomeFile !== ""){
                        <i (click)="visualizzaAllegato(fileAutorizzazione)" class="text-2xl pi pi-eye cursor-pointer"></i>
                      }
                      <p class="m-3">File caricato: {{fileAutorizzazione.nomeFile}}</p>
                    </div>
                  }

                </div>
              </div>
            }

          </div>
        </div>
        <div class="flex py-4 gap-2">
          <p-button
            label="Indetro"
            severity="secondary"
            (onClick)="prevCallback.emit(); scrollToElement('laurea_abilitazione')" />
          <p-button
            class="avanti"
            label="Avanti"
            (click)="nextCallback.emit(); scrollToElement('allegati')"
            [disabled]="iscrizioneForm!.get('dichiarazioni')?.invalid" />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- ALLEGATI -->
    <p-stepperPanel formGroupName="allegati">
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('allegati')!!.invalid ? 'invalid' : 'valid'"
          class="allegati bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('allegati')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-paperclip"></i>
            </span>
            <span class="text-xl font-bold">ALLEGATI</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">

            @for(file of files; track $index){
              <p class="m-3 md:m-4 w-11" [innerHTML]="file.descrizione"></p>
              <div class="card flex justify-content-start align-items-center">
                <p-fileUpload
                  #fileUpload
                  class="ml-4 mr-4"
                  mode="basic"
                  name="demo[]"
                  chooseIcon="pi pi-upload"
                  customUpload="true"
                  [accept]="file.tipo === 'Fototessera' ? 'image/*.jpeg' : 'application/pdf'"
                  maxFileSize="10000000"
                  (uploadHandler)="onBasicUploadAuto($event, file.tipo, fileUpload)"
                  [auto]="true"
                  chooseLabel="{{file.chooseLabel}}"
                  [ngStyle]="{'background-color': file.nomeFile !== '' ? '#6094C0' : '#D84347', 'border-radius': '5px'}"
                />
                @if(file.nomeFile !== ""){
                  <i (click)="visualizzaAllegato(file)" class="text-2xl pi pi-eye cursor-pointer"></i>
                }
                <p class="m-3">File caricato: {{file.nomeFile}}</p>
              </div>
            }
            <p class="m-3 mt-4 text-sm">(Capacità massima consentita dei file: 10MB;
              Formato file supportato per Fototessera: .jpeg, per gli altri file caricare: .pdf)</p>

          </div>
        </div>
        <div class="flex py-4 gap-2">
          <p-button
            label="Indetro"
            severity="secondary"
            (onClick)="prevCallback.emit(); scrollToElement('dichiarazioni')" />
          <p-button
            class="avanti"
            label="Avanti"
            (click)="nextCallback.emit(); scrollToElement('tesserino')"
            [disabled]="iscrizioneForm!.get('allegati')?.invalid" />
        </div>
      </ng-template>
    </p-stepperPanel>

    @if(regioneLabel === 'Puglia'){
      <!-- TESSERINO -->
      <p-stepperPanel formGroupName="tesserino">
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button [ngClass]="iscrizioneForm!.get('tesserino')!!.invalid ? 'invalid' : 'valid'"
            class="tesserino bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
            (click)="onClick.emit(); scrollToElement('tesserino')">
              <span class="w-3rem h-3rem flex align-items-center justify-content-center">
                <i class="text-2xl pi pi-credit-card"></i>
              </span>
              <span class="text-xl font-bold">TESSERINO</span>
          </button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
          <div class="flex flex-column h-auto">
            <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">
              <div class="flex align-items-center text-sm sm:text-base m-2 mb-3 md:m-4 w-11">
  
                <p-checkbox
                  formControlName="tesserino"
                  [binary]="true"
                  inputId="tesserino"
                  class="mr-2" />
                <label for="tesserino">
                  Se si desidera avere il tesserino è necessario barrare la presente casella ed effettuare un versamento, a mezzo bonifico,
                  di € 5,00 sul c/c postale IT90J0760104000000015399702, intestato a Ordine Psicologi Regione Puglia - Via F.lli Sorrentino
                  n.6 - 70126 - Bari, con la seguente causale: “Contributo per il Tesserino - nome e cognome richiedente"
                </label>
              </div>
  
              @if(iscrizioneForm!.get('tesserino.tesserino')?.value){
                <p @fadeInOut class="m-3 md:m-4 w-11">{{fileTesserino.descrizione}}</p>
                <div @fadeInOut class="card mb-4 md:mb-2 flex justify-content-start align-items-center">
                  <p-fileUpload
                    #fileUpload
                    class="ml-4 mr-4"
                    mode="basic"
                    name="demo[]"
                    chooseIcon="pi pi-upload"
                    customUpload="true"
                    accept="application/pdf"
                    maxFileSize="10000000"
                    (uploadHandler)="onBasicUploadAuto($event, fileTesserino.tipo, fileUpload)"
                    [auto]="true"
                    chooseLabel="{{fileTesserino.chooseLabel}}"
                    [ngStyle]="{'background-color': fileTesserino.nomeFile !== '' ? '#6094C0' : '#D84347', 'border-radius': '5px'}"
                  />
                  @if(fileTesserino.nomeFile !== ""){
                    <i (click)="visualizzaAllegato(fileTesserino)" class="text-2xl pi pi-eye cursor-pointer"></i>
                  }
                  <p class="m-3">File caricato: {{fileTesserino.nomeFile}}</p>
                </div>
              }
  
            </div>
          </div>
          <div class="flex py-4 gap-2">
            <p-button
              label="Indetro"
              severity="secondary"
              (onClick)="prevCallback.emit(); scrollToElement('allegati')" />
            <p-button
              class="avanti"
              label="Avanti"
              (click)="nextCallback.emit(); scrollToElement('trattamentoDati')" />
          </div>
        </ng-template>
      </p-stepperPanel>
    }

    <!-- TRATTAMENTO DATI -->
    <p-stepperPanel formGroupName="trattamentoDati">
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <button [ngClass]="iscrizioneForm!.get('trattamentoDati')!!.invalid ? 'invalid' : 'valid'"
          class="trattamentoDati bg-transparent border-none flex justify-content-start align-items-center cursor-pointer"
          (click)="onClick.emit(); scrollToElement('trattamentoDati')">
            <span class="w-3rem h-3rem flex align-items-center justify-content-center">
              <i class="text-2xl pi pi-info-circle"></i>
            </span>
            <span class="text-xl font-bold">TRATTAMENTO DEI DATI</span>
        </button>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-auto">
          <div class="border-2 surface-border border-round surface-ground flex-auto flex flex-column justify-content-center align-items-start font-medium w-11 pt-3 md:p-4 md:pt-5">

            <div class="flex align-items-center m-4 mb-2">
              <p-checkbox
                formControlName="trattamentoDatiFlag"
                [binary]="true"
                inputId="trattamentoDatiFlag"
                class="mr-2" />
              <label for="trattamentoDatiFlag">
                Con la presente autorizzo il trattamento dei dati forniti ai sensi
                del D. Lgs.vo n°196/2003 e del Regolamento Europeo 2016/679 <strong class="md:text-lg">(obbligatorio)</strong>
              </label>
            </div>

            <div class="flex align-items-center m-4">
            <p-button
              class="trattButton"
              label="Visualizza Trattamento Dati Personali"
              (click)="openPdfViewer()" />
            </div>

            @if(regioneLabel === 'Piemonte'){
              <div class="flex align-items-center m-4 mb-2">
                <p-checkbox
                  formControlName="consensoEmailFlag"
                  [binary]="true"
                  inputId="consensoEmailFlag"
                  class="mr-2" />
                <label for="consensoEmailFlag">
                  Consenso informato per il trattamento dell'indirizzo email e pec
                  <strong class="md:text-lg"> (obbligatorio)</strong>
                </label>
              </div>

              @if(iscrizioneForm!.get('trattamentoDati.consensoEmailFlag')?.value){
                <div @fadeInOut class="font-italic font-bold px-4 mt-3">
                  <p class="line-height-2 mb-4" style="max-width: 70rem;">
                    La Sua e-mail verrà utilizzata per la trasmissione di comunicazioni e informazioni
                    strettamente pertinenti le finalità istituzionali e le attività dell’Ordine stesso
                  </p>
                  <p class="line-height-2 mb-4" style="max-width: 70rem;">
                    I dati personali da Lei forniti all’Ordine degli Psicologi sono oggetto di trattamento nel
                    rispetto del Reg. UE 2016/679 (GDPR), del D.Lgs. 30 giugno 2003 n. 196 e degli obblighi di
                    riservatezza ivi previsti
                  </p>
                  <p class="line-height-2 mb-4">
                    L’Ordine si impegna a non divulgare a terzi né a concedere in uso l’indirizzo
                    elettronico sopra indicato ed assicura altresì che i dati saranno raccolti nel nostro data
                    base e registrati in modo da consentirne l’accesso solo a soggetti dell’Ordine degli Psicologi
                    autorizzati. L’informativa completa è disponibile sul sito web del Consiglio dell’Ordine
                  </p>
    
                </div>
              }
            }

          </div>
        </div>

        <div class="flex flex-column sm:flex-row py-4 gap-2">
          <p-button
            label="Indetro"
            severity="secondary"
            (onClick)="prevCallback.emit(); scrollToElement('tesserino')" />
        </div>

      </ng-template>
    </p-stepperPanel>

  </p-stepper>

  <!-- PULSANTI DI INVIO -->
  <div class="m-4 flex flex-wrap">

    <p-toast position="bottom-center" />
    <p-confirmDialog #cd>
      <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
            <i class="pi pi-question text-5xl"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">
            {{ message.header }}
          </span>
          <p class="mb-0">{{ message.message }}</p>
          <div class="flex align-items-center gap-2 mt-4">
           <button
              pButton
              label="Annulla"
              (click)="cd.reject()"
              class="cancelButton">
            </button>
            @if(invia){
              <button
                pButton
                label="Invia"
                (click)="cd.accept()"
                class="acceptButton">
              </button>
            }
            @else{
              <button
                pButton
                label="Salva"
                (click)="cd.accept()"
                class="acceptButton">
              </button>
            }
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>
    @if(rifiutata){
      <p-button
      class="inviaButton m-2"
      label="Reinvia Domanda"
      (click)="inviaDomandaRifiutata()"
      [disabled]="iscrizioneForm!.invalid" />
    }
    @else{
      @if(bozza){
        <p-button
          class="bozzaButton m-2"
          label="Salva Bozza"
          (click)="modificaBozza()" />
          <p-button
          class="inviaButton m-2"
          label="Invia Domanda"
          (click)="inviaDomandaBozza()"
          [disabled]="iscrizioneForm!.invalid" />
        }
        @else{
          <p-button
            class="bozzaButton m-2"
            label="Salva Bozza"
            (click)="generaBozza()" />
          <p-button
            class="inviaButton m-2"
            label="Invia Domanda"
            (click)="inviaDomanda()"
            [disabled]="iscrizioneForm!.invalid" />
        }
    }
  </div>

</form>
