import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environment/environment';
import { Observable } from 'rxjs';
import { Allegati, Allegato, DatiIscrizione, DropdownIscrizioniDto, PraticheSalvateDto, PraticheSalvateUtenteDto, ReponseInviaSalva } from '../models/master-data';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  private _http = inject(HttpClient);
  private dropdown_api: string = `${environment.baseUrl}/dropdown`;
  private iscrizione_api: string = `${environment.baseUrl}/iscrizione`;
  private allegati_api: string = `${environment.baseUrl}/files`;

  //fetch dei dropdown per il modulo iscrizione
  getDropdownIscrizioni(sezione: string): Observable<DropdownIscrizioniDto>{
    return this._http.get<DropdownIscrizioniDto>(`${this.dropdown_api}/iscrizione/${sezione}`)
  }

  //gestione delle pratiche
  postGeneraBozza(datiIscrizione: DatiIscrizione): Observable<ReponseInviaSalva> {
    return this._http.post<ReponseInviaSalva>(`${this.iscrizione_api}/salva-bozza`, datiIscrizione)
  }
  getPraticheSalvate(codiceFiscale: string): Observable<PraticheSalvateDto>{
    const data: PraticheSalvateUtenteDto = {
      codiceFiscale: codiceFiscale
    };
    return this._http.post<PraticheSalvateDto>(`${this.iscrizione_api}/pratiche-salvate-utente`, data)
  }
  getCaricaPratica(id: number): Observable<DatiIscrizione>{
    return this._http.get<DatiIscrizione>(`${this.iscrizione_api}/carica-pratica/${id}`)
  }
  putModificaBozza(datiIscrizione: DatiIscrizione, id: number|undefined): Observable<string> {
    return this._http.put<string>(`${this.iscrizione_api}/modifica-bozza/${id}`, datiIscrizione, { responseType: 'text' as 'json' })
  }

  //invio delle domande
  postInviaDomanda(datiIscrizione: DatiIscrizione): Observable<ReponseInviaSalva> {
    return this._http.post<ReponseInviaSalva>(`${this.iscrizione_api}/invia-domanda`, datiIscrizione)
  }
  putInviaDomandaDaBozza(datiIscrizione: DatiIscrizione, id: number|undefined): Observable<string> {
    return this._http.put<string>(`${this.iscrizione_api}/invia-domanda-da-bozza/${id}`, datiIscrizione, { responseType: 'text' as 'json' })
  }
  putInviaDomanRifiutata(datiIscrizione: DatiIscrizione, id: number|undefined): Observable<string> {
    return this._http.put<string>(`${this.iscrizione_api}/modifica-pratica-rifiutata/${id}`, datiIscrizione, { responseType: 'text' as 'json' })
  }

  //cancellazione bozza
  deleteBozza(id: number): Observable<string>{
    return this._http.delete<string>(`${this.iscrizione_api}/elimina-bozza/${id}`, { responseType: 'text' as 'json' })
  }

  verificaPraticaInviata(codiceFiscale: string): Observable<boolean>{
    const data: PraticheSalvateUtenteDto = {
      codiceFiscale: codiceFiscale
    };
    return this._http.post<boolean>(`${this.iscrizione_api}/verifica-pratica-inviata`, data)
  }

  //stampa bozza
  getStampaBozza(id: number): Observable<any>{
    return this._http.get(`${environment.baseUrl}/jr/bozza/${id}`, { responseType: 'blob' })
  }

  //gestione degli allegati
  caricaAllegati(file: File, datiFile: Allegato): Observable<string> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('idPratica', datiFile.idPratica.toString());
    formData.append('codiceFiscale', datiFile.codiceFiscale);
    formData.append('dimensioneFile', datiFile.dimensioneFile.toString());
    formData.append('tipoFile', datiFile.tipoFile);
    return this._http.post<string>(`${this.allegati_api}/upload/${file.name}`, formData, { responseType: 'text' as 'json' });
  }
  getFileAllegati(id: number): Observable<Allegati[]>{
    return this._http.get<Allegati[]>(`${this.allegati_api}/download/${id}`);
  }
  getFileAllegato(id: number, tipoFile: string): Observable<Blob>{
    return this._http.get<Blob>(`${this.allegati_api}/download/${id}/${tipoFile}`, { responseType: 'blob' as 'json' });
  }
  deleteAllegati(id: number): Observable<string>{
    return this._http.delete<string>(`${this.allegati_api}/delete/${id}`, { responseType: 'text' as 'json' })
  }

}
