<div [ngClass]="{'h-screen': home}" class="flex flex-column">
  
  <div class="text-center w-full">
    
    @if(home) {
      <div class="z-2 fixed w-full flex justify-content-center align-items-center titolo">
        <div class="m-3 w-3 flex justify-content-start">
          <p-menu
            #menu
            [model]="items"
            [popup]="true"
            class="custom-menu" />
          <p-button
            class="block userBtn"
            (click)="menu.toggle($event)"
            icon="pi pi-user"
            [label]="userDisplayName" />
        </div>
        <p class="border-solid border-1 border-y-none sm:border-none w-full font-bold p-4 md:text-3xl">
          {{message}}
        </p>
        <div
          class="m-3 w-3 cursor-pointer font-bold"
          [routerLink]="'/pratiche_salvate'">
          Le mie pratiche
        </div>
      </div>
    }
    @else if(actualRoute === '/pratiche_salvate'){
      <div class="z-2 fixed w-full flex justify-content-between align-items-center titolo">
        <div class="w-2">
          <p-button
            pTooltip="Indietro"
            tooltipPosition="bottom"
            class="backBtn ml-4"
            icon="pi pi-arrow-circle-left"
            [routerLink]="'/'" />
        </div>
        <p class="w-full font-bold py-4 mx-2 md:text-3xl">
          {{message}}
        </p>
        <div
          pTooltip="Home"
          tooltipPosition="bottom"
          [routerLink]="'/'"
          class="logo w-2 mr-4 cursor-pointer">
          <img class="h-3rem" src="assets/images/{{regione}}.png" alt="logo">
        </div>
      </div>
    }
    @else{
      <div class="z-2 w-full flex justify-content-between align-items-center titolo">
        <div class="w-2">
          <p-button
            pTooltip="Indietro"
            tooltipPosition="bottom"
            class="backBtn ml-4"
            icon="pi pi-arrow-circle-left"
            (onClick)="goBack()" />
        </div>
        <p class="w-full font-bold py-4 mx-2 md:text-3xl">{{message}}</p>
        <div
          pTooltip="Home"
          tooltipPosition="bottom"
          [routerLink]="'/'"
          class="logo mr-4 w-2 cursor-pointer" >
            <img class="h-3rem" src="assets/images/{{regione}}.png" alt="logo">
        </div>
      </div>
    }
      
  </div>
  <div class="bg-color pt-4">
    <router-outlet></router-outlet>
  </div>
</div>