import { Validators } from "@angular/forms";

export const environment = {
  production: false,
  /* baseUrl VM: 'https://iscrizione-opp.synergica.tech/moduli-api',
  baseUrl locale: 'http://localhost:8084/moduli-api', */
  baseUrl: 'https://iscrizione-opp.synergica.tech/moduli-api',
  authServerUrl: 'https://auth3.synergica.tech/realms/opp-iscritti',
  clientId: 'moduli-web',
  regione: 'opp',
  regioneLabel: 'Piemonte',
  extraTitleA: ' - SEZ. A - (laurea quinquennale)',
  extraTitleB: ' - SEZ. B - (laurea triennale)',
  regioneCC: '75.00</b> effettuato sul c/c bancario 1564/23 - Banca Popolare di Sondrio - Agenzia di Torino - IBAN IT22 F056 9601 0000 0000 1564 X23 intestato a “Ordine degli Psicologi del Piemonte”',
  provincie: [],
  tesserinoP: '</b> (PER LA STAMPA DEL TESSERINO DI APPARTENENZA ALL’ORDINE):',
  formConfig: {
    anagrafica: {
      sezioneAlbo: { defaultValue: '', disabled: true, validators: [Validators.required] },
      nome: { defaultValue: '', readonly: true, validators: [Validators.required] },
      cognome: { defaultValue: '', readonly: true, validators: [Validators.required] },
      codiceFiscale: { defaultValue: '', readonly: true, validators: [Validators.required, Validators.minLength(16), Validators.maxLength(16)] },
      sesso: { defaultValue: null, validators: [Validators.required] },
      dataNascita: { defaultValue: null, validators: [Validators.required] },
      comuneNascita: { defaultValue: null, validators: [] },
      provinciaNascita: { defaultValue: null, validators: [] },
      cittadinanza: { defaultValue: null, validators: [] },
      permessoSoggiorno: { defaultValue: false, disabled: true, validators: [] }
    },
    abitazione: {
      residenzaComune: { defaultValue: null, validators: [] },
      residenzaProvincia: { defaultValue: null, validators: [] },
      residenzaIndirizzo: { defaultValue: '', validators: [Validators.required] },
      residenzaCitofono: { defaultValue: null, validators: [] },
      residenzaCap: { defaultValue: '', validators: [Validators.required] },
      residenzaStato: { defaultValue: null, validators: [] },
  
      domicilioFlag: { defaultValue: false, validators: [] },
      domicilioComune: { defaultValue: null, disabled: true, validators: [] },
      domicilioProvincia: { defaultValue: null, disabled: true, validators: [] },
      domicilioIndirizzo: { defaultValue: '', disabled: true, validators: [] },
      domicilioCitofono: { defaultValue: null, validators: [] },
      domicilioCap: { defaultValue: '', disabled: true, validators: [] },
      domicilioStato: { defaultValue: null, disabled: true, validators: [] },

      corrispondenzaPostale: { defaultValue: 'R', disabled: true, validators: [] }
    },
    documento: {
      tipoDocumento: { defaultValue: null, validators: [Validators.required] },
      numeroDocumento: { defaultValue: '', validators: [Validators.required] },
      dataRilascioDocumento: { defaultValue: null, validators: [Validators.required] },
      rilasciatoDa: { defaultValue: '', validators: [Validators.required] }
    },
    contatti: {
      email: { defaultValue: '', validators: [Validators.required] },
      pec: { defaultValue: '', validators: [] },
      telefono: { defaultValue: '', validators: [] },
      cellulare: { defaultValue: '', validators: [Validators.required] }
    },
    laurea_abilitazione: {
      titolo: { defaultValue: null, validators: [Validators.required] },
      univLaurea: { defaultValue: null, validators: [Validators.required] },
      corsoLaurea: { defaultValue: null, validators: [Validators.required] },
      laureaData: { defaultValue: null, validators: [Validators.required] },
  
      laureaAbilitante: { defaultValue: true, validators: [] },
      universitaAbilitazione: { defaultValue: null, disabled: true, validators: [] },
      abilitazioneSessione: { defaultValue: '', disabled: true, validators: [] },
      abilitazioneData: { defaultValue: null, disabled: true, validators: [] }
    },
    dichiarazioni: {
      delittiFlag: { defaultValue: false, validators: [] },
      altriConsigliFlag: { defaultValue: false, validators: [] },
      iscrizioneAltroOrdineFlag: { defaultValue: false, validators: [] },
      regioneAltroOrdine: { defaultValue: null, disabled: true, validators: [] },
      dataCancellazioneAltroOrdine: { defaultValue: null, disabled: true, validators: [] },
      dipendentePubblico: { defaultValue: false, validators: [] },
      dipendentePubblicoPresso: { defaultValue: '', disabled: true, validators: [] },
      dipendentePubblicoRuolo: { defaultValue: '', disabled: true, validators: [] },
      articolo82: { defaultValue: null, disabled: true, validators: [] },
      autorizzazione: { defaultValue: false, disabled: true, validators: [] }
    },
    allegati: {
      allegatiFile: { defaultValue: false, validators: [] }
    },
    trattamentoDati: {
      trattamentoDatiFlag: { defaultValue: false, validators: [] },
      consensoEmailFlag: { defaultValue: false, validators: [] }
    }
  }

}
