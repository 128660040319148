import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from './environment/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './auth/auth.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  title = 'webapp';

  private router = inject(Router);
  private location = inject(Location);
  private oAuthService = inject(OAuthService);

  regione = environment.regione;

  userDisplayName: string = '';
  items: any;
  actualRoute: string = '';
  message: string = "";
  home: boolean = true;

  moduli: string[] = ['iscrizione_sezione_a', 'iscrizione_sezione_b', 'iscrizione_sezione_a_+_psicoterapia'];

  ngOnInit() {
    this.actualRoute = this.router.url;
    this.handleButtons();
    this.checkRouting();
    this.checkRoute();
    this.checkFavicon();
    this.loadAuth();
  }

  loadAuth(){
    this.oAuthService.configure(authCodeFlowConfig);
    this.oAuthService.loadDiscoveryDocumentAndLogin().then(() => {
      const user = this.oAuthService.getIdentityClaims();
      this.userDisplayName = user['name'];
    }).catch(err => {
      console.error('Errore durante il login OAuth:', err);
    });
    this.oAuthService.setupAutomaticSilentRefresh();
  }

  private handleButtons(){
    this.items = [
      {
        label: 'Logout',
        icon: 'pi pi-power-off',
        command: () => {
          this.oAuthService.revokeTokenAndLogout();
        }
      }
    ];
  }

  checkFavicon(){
    let newFavicon = '';
    switch(this.regione){
      case 'opp':
        newFavicon = './assets/images/favicon_opp.png';
        break;
      case 'oprp':
        newFavicon = './assets/images/favicon_oprp.ico';
        break;
      default:
        newFavicon = './assets/images/favicon.ico';
        break;
    }
    const link: any = document.querySelector('link[rel="icon"]');
    link.href = newFavicon;
  }

  checkRouting(){
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.actualRoute = event.url;
      this.checkRoute();
    });
  }

  goBack(): void {
    this.location.back();
  }

  checkRoute(){
    if(this.router.url.includes('/')){
      this.home = true;
      this.message = "Seleziona un modulo di iscrizione";
    }
    if(this.router.url.includes('/iscrizione_sezione_a')){
      this.home = false;
      this.message = "Modulo di iscrizione Sezione A";
    }
    if(this.router.url.includes('/iscrizione_sezione_b') ){
      this.home = false;
      this.message = "Modulo di iscrizione Sezione B";
    }
    if(this.router.url.includes('/iscrizione_sezione_ap') ){
      this.home = false;
      this.message = "Modulo di iscrizione Sezione A + Psicoterapia";
    }
    if(this.router.url.includes('/pratiche_salvate') ){
      this.home = false;
      this.message = "Le mie pratiche";
    }
  }

}
