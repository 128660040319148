import { Component, HostListener, inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Allegati, Allegato, Comune, DatiIscrizione, DocumentiIdentita, DropdownIscrizioniDto, DropdownValues, FileCaricati, FileCaricato, Nazione, Ordine, Provincia, SezioniAlbo, Titolo, Universita } from '../../models/master-data';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { MasterService } from '../../service/master.service';
import { environment } from '../../environment/environment';
import { merge, Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-iscr-sez-b',
  templateUrl: './iscr-sez-b.component.html',
  styleUrl: './iscr-sez-b.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })), // Initial state: hidden
      state('*', style({ opacity:2})), // Final state: visible
      transition('void <=> *', animate('300ms ease-in-out')), // Animation duration and easing
    ]),
  ]
})
export class IscrSezBComponent implements OnInit {

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  private master_service = inject(MasterService);
  private fb = inject(FormBuilder);
  private oAuthService = inject(OAuthService);

  //enviroment data
  regione = environment.regione;
  regioneLabel = environment.regioneLabel;
  extraTitle = environment.extraTitleB;
  regioneCC = environment.regioneCC;
  tesserinoP = environment.tesserinoP;
  provinceRegione: string[] = environment.provincie;

  iscrizioneForm: FormGroup|undefined;

  //valori ottenuti da db
  titoli: Titolo[] = [];
  comuni: Comune[] = [];
  province: Provincia[] = [];
  nazioni: Nazione[] = [];
  universita: Universita[] = [];
  documentiIdentita: DocumentiIdentita[] = [];
  sezioniAlbo: SezioniAlbo[] =[];
  ordini: Ordine[] = [];
  allegati: FileCaricati[] = [];
  corrispondenza: any[] = [
    { name: 'Residenza', value: 'R' },
    { name: 'Domicilio', value: 'D' }
  ];

  filtrati: any[] = [];
  bozza: boolean = false;
  invia: boolean = false;
  rifiutata: boolean = false;
  residenzaInRegione: boolean = false;
  domicilioInRegione: boolean = false;

  iscrittoProva: DatiIscrizione = {
    sezioneAlbo: {
      id: 0,
      descrizione: '',
      codice: ''
    },
    nome: '',
    cognome: '',
    codiceFiscale: '',
    sesso: '',
    dataNascita: '',
    comuneNascita: null,
    provinciaNascita: null,
    cittadinanza: null,
    residenzaComune: null,
    residenzaProvincia: null,
    residenzaIndirizzo: '',
    residenzaCap: '',
    residenzaStato: null,

    domicilioFlag: false,
    domicilioComune: null,
    domicilioProvincia: null,
    domicilioIndirizzo: '',
    domicilioCap: '',
    domicilioStato: null,

    tipoDocumento: null,
    numeroDocumento: '',
    dataRilascioDocumento: '',
    rilasciatoDa: '',

    email: '',
    pec: '',
    telefono: '',
    cellulare: '',

    titolo: null,
    univLaurea: null,
    corsoLaurea: '',
    laureaData: '',

    laureaAbilitante: true,
    universitaAbilitazione: null,
    abilitazioneSessione: '',
    abilitazioneData: '',

    delittiFlag: false,
    altriConsigliFlag: false,
    dipendentePubblico: false,
    dipendentePubblicoPresso: '',
    dipendentePubblicoRuolo: '',
    articolo82: null,

    trattamentoDatiFlag: false

  }

  //dropdown options
  sessoOptions: DropdownValues[] = [
    { name: 'Maschio', value: 'M' },
    { name: 'Femmina', value: 'F' }
  ]

  articolo82Options: any[] = [
    {
      name: "di possedere l'autorizzazione a svolgere la libera professione e ne allegato copia",
      value: true
    },
    {
      name: "di NON possedere l'autorizzazione scritta a svolgere la libera professione",
      value: false
    }
  ]

  //gestione caricamento file
  files: FileCaricato[] = [
    {
      tipo: "Marca_da_Bollo",
      descrizione: "<b>Marca da Bollo da 16,00€:</b>",
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Fototessera",
      descrizione: "<b>Fototessera" + this.tesserinoP,
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Tassa_Concessioni_Governative",
      descrizione: `<b>Ricevuta del versamento della tassa sulle concessioni governative di € 168.00</b> effettuato sul c/c postale 8003
                  intestato a Agenzia delle Entrate - Centro Operativo di Pescara (bollettino prestampato reperibile in qualsiasi ufficio postale)
                  Codice causale n. 8617 - tipo di versamento: "tassa erariale iscrizione Albo Psicologi":`,
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Tassa_iscrizione_ad_Albo",
      descrizione: `<b>Ricevuta del versamento a mezzo bonifico della tassa di iscrizione all'Albo di €` 
      + this.regioneCC +
      `, con la seguente causale: “Tassa iscrizione Albo 1° anno - nome e cognome richiedente”:`,
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Documento_di_Identità",
      descrizione: "<b>Documento di identità:</b>",
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Tessera_Sanitaria",
      descrizione: "<b>Tessera Sanitaria:</b>",
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    }
  ];
  filePermessoSoggiorno: FileCaricato = {
    tipo: "Permesso_Soggiorno",
    descrizione: "I cittadini extracomunitari devono allegare il permesso di soggiorno in corso di validità all'atto della richiesta di iscrizione e, successivamente, inviare alla segreteria dell’Ordine copia dei rinnovi:",
    nomeFile: "",
    file: null,
    chooseLabel: "Upload"
  };
  fileAutorizzazione: FileCaricato = {
    tipo: "Autorizzazione",
    descrizione: "Copia dell'Autorizzazione della Libera Professione",
    nomeFile: "",
    file: null,
    chooseLabel: "Upload"
  }
  fileTesserino: FileCaricato = {
    tipo: "Tesserino",
    descrizione: "Allegare copia del bonifico",
    nomeFile: "",
    file: null,
    chooseLabel: "Upload"
  }

  ngOnInit(): void {

    this.createForm();

    this.getBozza();

    this.dataLoad();
    this.loadInfo();

    this.iscrizioniCheck();
    this.updateLabel(window.innerWidth);

  }

  createForm(){
    this.iscrizioneForm = this.fb.group({});

    (Object.keys(environment.formConfig) as Array<keyof typeof environment.formConfig>).forEach((sectionKey) => {
      const sectionConfig = environment.formConfig[sectionKey];
      const sectionGroup = this.fb.group({});

      (Object.keys(sectionConfig) as Array<keyof typeof sectionConfig>).forEach((fieldKey) => {
        const fieldConfig = sectionConfig[fieldKey] as {
          defaultValue: any; 
          disabled?: boolean;
          validators?: any[];
        };
  
        sectionGroup.addControl(
          fieldKey,
          this.fb.control(
            { value: fieldConfig.defaultValue, disabled: fieldConfig.disabled || false },
            fieldConfig.validators || []
          )
        );
      });

      this.iscrizioneForm!.addControl(sectionKey, sectionGroup);
    });
    this.iscrizioneForm!.get('anagrafica')?.patchValue({ sezioneAlbo: 'Sezione B' });

    this.iscrizioneForm!.get('anagrafica.comuneNascita')!.setValidators([Validators.required, this.customComuneValidators()]);
    this.iscrizioneForm!.get('anagrafica.provinciaNascita')!.setValidators([Validators.required, this.customProvinciaValidators()]);
    this.iscrizioneForm!.get('anagrafica.cittadinanza')!.setValidators([Validators.required, this.customNazioneValidators('cittadinanza')]);
    this.iscrizioneForm!.get('abitazione.residenzaComune')!.setValidators([Validators.required, this.customComuneValidators()]);
    this.iscrizioneForm!.get('abitazione.residenzaProvincia')!.setValidators([Validators.required, this.customProvinciaValidators()]);
    this.iscrizioneForm!.get('abitazione.residenzaStato')!.setValidators([Validators.required, this.customNazioneValidators('stato')]);
    this.iscrizioneForm!.get('dichiarazioni.delittiFlag')!.setValidators([Validators.required, this.customTrueValidators()]);
    this.iscrizioneForm!.get('dichiarazioni.altriConsigliFlag')!.setValidators([Validators.required, this.customTrueValidators()]);
    this.iscrizioneForm!.get('allegati.allegatiFile')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    this.iscrizioneForm!.get('trattamentoDati.trattamentoDatiFlag')!.setValidators([Validators.required, this.customTrueValidators()]);
    this.regioneLabel === 'Piemonte' ? 
      this.iscrizioneForm!.get('trattamentoDati.consensoEmailFlag')!.setValidators([Validators.required, this.customTrueValidators()])
      : null;
    this.markAllAsDirty(this.iscrizioneForm);
  }

  getBozza(){
    if(this.route.snapshot.data['bozza']){
      this.iscrittoProva = this.route.snapshot.data['bozza'];
      this.bozza = true;
      if(this.iscrittoProva.statoIscrizione!.codice === 'R'){
        this.rifiutata = true;
      }

      this.master_service.getFileAllegati(this.iscrittoProva.id!).subscribe({
        next: (allegati: Allegati[]) => {
          allegati.forEach((allegato) => {
            this.onBeforeUpload(allegato);
          })
          const allFilesUploaded = this.files.every(f => f.nomeFile !== "");
          if(allFilesUploaded){
            this.iscrizioneForm!.get('allegati.allegatiFile')!.setValue(allFilesUploaded);
          }
        },
        error: err =>{
          this.messageService.add({ severity: 'error', summary: 'Errore', detail: err.error });
        }
      });

    }
  }

  //data from db loading
  dataLoad(){
    const dropdownData: DropdownIscrizioniDto = this.route.snapshot.data['dropdown'];
    this.titoli = dropdownData.titoli;
    this.comuni = dropdownData.comuni;
    this.province = dropdownData.province;
    this.nazioni = dropdownData.nazioni;
    this.universita = dropdownData.universita;
    this.documentiIdentita = dropdownData.documentiIdentita;
    this.sezioniAlbo = dropdownData.sezioniAlbo;
    this.ordini = dropdownData.ordini;
  }

  //inserisci le informazioni ottenute dallo spid o da bozza
  loadInfo(){

    const user = this.oAuthService.getIdentityClaims();
    const indirizzo = user['address'].split(' - ')[0];
    const comuneResidenza = user['address'].split(' - ')[1];

    if(this.iscrittoProva.domicilioFlag){
      ['domicilioIndirizzo', 'domicilioCap', 'domicilioComune', 'domicilioProvincia', 'domicilioStato', 'corrispondenzaPostale'].forEach(field => {
        const control_name = `abitazione.${field}`;
        this.iscrizioneForm!.get(control_name)!.enable();
        this.iscrizioneForm!.get(control_name)!.setValidators(Validators.required);
      });
      this.iscrizioneForm!.get('abitazione.domicilioComune')!.setValidators([Validators.required, this.customComuneValidators()]);
      this.iscrizioneForm!.get('abitazione.domicilioProvincia')!.setValidators([Validators.required, this.customProvinciaValidators()]);
      this.iscrizioneForm!.get('abitazione.domicilioStato')!.setValidators([Validators.required, this.customNazioneValidators('stato')]);
    }
    if(this.iscrittoProva.laureaAbilitante){
      ['universitaAbilitazione', 'abilitazioneData', 'abilitazioneSessione'].forEach(field => {
        const control_name = `laurea_abilitazione.${field}`;
        this.iscrizioneForm!.get(control_name)!.enable();
        this.iscrizioneForm!.get(control_name)!.setValidators(Validators.required);
      })
    }
    if(this.iscrittoProva.iscrizioneAltroOrdineFlag){
      ['regioneAltroOrdine', 'dataCancellazioneAltroOrdine'].forEach(field => {
        const control_name = `dichiarazioni.${field}`;
        this.iscrizioneForm!.get(control_name)!.enable();
        this.iscrizioneForm!.get(control_name)!.setValidators(Validators.required);
      })
    }
    if(this.iscrittoProva.dipendentePubblico){
      ['dipendentePubblicoPresso', 'articolo82'].forEach(field => {
        const control_name = `dichiarazioni.${field}`;
        this.iscrizioneForm!.get(control_name)!.enable();
        this.iscrizioneForm!.get(control_name)!.setValidators(Validators.required);
      })
      this.iscrizioneForm!.get('dichiarazioni.dipendentePubblicoRuolo')!.enable();
    }
    if(this.iscrittoProva.cittadinanza !== null && this.iscrittoProva.cittadinanza.nazionalita !== "Italiana"){
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.enable();
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    this.articolo82Check(this.iscrittoProva.articolo82);
    if(this.regioneLabel === 'Puglia'){
      this.checkAbitazionePuglia();
      this.tesserinoCheck(this.iscrittoProva.tesserino);
    }

    this.iscrizioneForm!.patchValue({

      anagrafica: {
        nome: user['given_name'],
        cognome: user['family_name'],
        codiceFiscale: user['preferred_username'].toUpperCase(),
        sesso: this.dropdownSearch(this.iscrittoProva.sesso || user['gender'], this.sessoOptions),
        dataNascita: user['dateOfBirth'] ? new Date(user['dateOfBirth']) : null,
        comuneNascita: this.iscrittoProva.comuneNascita || this.comuni.find(com => comuneResidenza === com.nome),
        provinciaNascita: this.iscrittoProva.provinciaNascita || this.province.find(prov => user['countyOfBirth'] === prov.codice),
        cittadinanza: this.iscrittoProva.cittadinanza || (
          this.province.some(prov => user['countyOfBirth'] === prov.codice)
            ? this.nazioni.find(naz => naz.nazionalita === 'Italiana')
            : null
        )
      },

      abitazione: {
        residenzaComune: this.iscrittoProva.residenzaComune || this.comuni.find(com => comuneResidenza === com.nome),
        residenzaProvincia: this.iscrittoProva.residenzaProvincia || this.province.find(prov => user['countyOfBirth'] === prov.codice),
        residenzaIndirizzo: this.iscrittoProva.residenzaIndirizzo || indirizzo,
        residenzaFrazione: this.regioneLabel === 'Puglia' ? this.iscrittoProva.residenzaFrazione : null,
        residenzaCitofono: this.regioneLabel === 'Piemonte' ? this.iscrittoProva.residenzaCitofono : null,
        residenzaCap: this.iscrittoProva.residenzaCap,
        residenzaStato: this.iscrittoProva.residenzaStato || (
          this.comuni.some(com => comuneResidenza === com.nome)
            ? this.nazioni.find(naz => naz.nazionalita === 'Italiana')
            : null
        ),

        domicilioFlag: this.iscrittoProva.domicilioFlag,
        domicilioComune: this.iscrittoProva.domicilioComune,
        domicilioProvincia: this.iscrittoProva.domicilioProvincia,
        domicilioIndirizzo: this.iscrittoProva.domicilioIndirizzo,
        domicilioFrazione: this.regioneLabel === 'Puglia' ? this.iscrittoProva.domicilioFrazione : null,
        domicilioCitofono: this.regioneLabel === 'Piemonte' ? this.iscrittoProva.domicilioCitofono : null,
        domicilioCap: this.iscrittoProva.domicilioCap,
        domicilioStato: this.iscrittoProva.domicilioStato,

        corrispondenzaPostale: (this.regioneLabel === 'Piemonte' || this.regioneLabel === 'Puglia')
          && this.iscrittoProva.corrispondenzaPostale ? this.iscrittoProva.corrispondenzaPostale : 'R'
      },

      documento: {
        tipoDocumento: this.iscrittoProva.tipoDocumento,
        numeroDocumento: this.iscrittoProva.numeroDocumento,
        dataRilascioDocumento: this.iscrittoProva.dataRilascioDocumento ? new Date(this.iscrittoProva.dataRilascioDocumento) : null,
        rilasciatoDa: this.iscrittoProva.rilasciatoDa
      },

      contatti: {
        email: this.iscrittoProva.email || user['email'],
        pec: this.iscrittoProva.pec,
        telefono: this.iscrittoProva.telefono,
        cellulare: this.iscrittoProva.cellulare || user['mobilePhone'],
        fax: this.regioneLabel === 'Puglia' ? this.iscrittoProva.fax : null,
      },

      laurea_abilitazione: {
        titolo: this.iscrittoProva.titolo,
        univLaurea: this.iscrittoProva.univLaurea,
        corsoLaurea: this.iscrittoProva.corsoLaurea,
        laureaData: this.iscrittoProva.laureaData ? new Date(this.iscrittoProva.laureaData) : null,

        laureaAbilitante: this.iscrittoProva.laureaAbilitante,
        universitaAbilitazione: this.iscrittoProva.universitaAbilitazione,
        abilitazioneSessione: this.iscrittoProva.abilitazioneSessione,
        abilitazioneData: this.iscrittoProva.abilitazioneData ? new Date(this.iscrittoProva.abilitazioneData) : null
      },

      dichiarazioni: {
        delittiFlag: this.iscrittoProva.delittiFlag,
        altriConsigliFlag: this.iscrittoProva.altriConsigliFlag,
        iscrizioneAltroOrdineFlag: this.regioneLabel === 'Piemonte' ? this.iscrittoProva.iscrizioneAltroOrdineFlag : null,
        regioneAltroOrdine: this.regioneLabel === 'Piemonte' ? 
          this.ordini.find(reg => reg.nome === this.iscrittoProva.regioneAltroOrdine)
          : null,
        dataCancellazioneAltroOrdine: this.regioneLabel === 'Piemonte' && this.iscrittoProva.dataCancellazioneAltroOrdine ? new Date(this.iscrittoProva.dataCancellazioneAltroOrdine!) : null,
        dipendentePubblico: this.iscrittoProva.dipendentePubblico,
        dipendentePubblicoPresso: this.iscrittoProva.dipendentePubblicoPresso,
        dipendentePubblicoRuolo: this.iscrittoProva.dipendentePubblicoRuolo,
        articolo82: this.iscrittoProva.articolo82
      },

      tesserino: {
        tesserino: this.regioneLabel === 'Puglia' ? this.iscrittoProva.tesserino : null,
      },

      trattamentoDati: {
        trattamentoDatiFlag: this.iscrittoProva.trattamentoDatiFlag,
        consensoEmailFlag: this.regioneLabel === 'Piemonte' ? this.iscrittoProva.consensoEmailFlag : null
      }

    });
  }

  //gestione dei vari checkbox
  iscrizioniCheck(){
    this.iscrizioneForm!.get('anagrafica.cittadinanza')?.valueChanges.subscribe(value => this.permessoSoggiornoCheck(value));
    this.iscrizioneForm!.get('abitazione.domicilioFlag')?.valueChanges.subscribe(value => this.domicilioCheck(value));
    this.iscrizioneForm!.get('dichiarazioni.iscrizioneAltroOrdineFlag')?.valueChanges.subscribe(value => this.iscrizioneAltroOrdineCheck(value));
    this.iscrizioneForm!.get('dichiarazioni.dipendentePubblico')?.valueChanges.subscribe(value => this.dipendentePubblicoCheck(value));
    this.iscrizioneForm!.get('dichiarazioni.articolo82')?.valueChanges.subscribe(value => this.articolo82Check(value));
    this.iscrizioneForm!.get('laurea_abilitazione.laureaAbilitante')?.valueChanges.subscribe(value => this.abilitazioneCheck(value));
    if(this.regioneLabel === 'Puglia'){
      this.iscrizioneForm!.get('tesserino.tesserino')?.valueChanges.subscribe(value => this.tesserinoCheck(value));
    }
  }
  permessoSoggiornoCheck(value: any){
    if(value && value.nazionalita !== 'Italiana' && value.nazionalita != null){
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.enable();
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    else{
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.setValue(false);
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.disable();
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.clearValidators();
      this.filePermessoSoggiorno = {
        tipo: "Permesso_Soggiorno",
        descrizione: "I cittadini extracomunitari devono allegare il permesso di soggiorno in corso di validità all'atto della richiesta di iscrizione e, successivamente, inviare alla segreteria dell’Ordine copia dei rinnovi:",
        nomeFile: "",
        file: null,
        chooseLabel: "Upload"
      }
      const index = this.allegati.findIndex(item => item.tipo === "Permesso_Soggiorno");
      if(index !== -1){
        this.allegati.splice(index, 1);
      }
    }
    this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')?.updateValueAndValidity();
  }
  domicilioCheck(value: any){
    ['domicilioIndirizzo', 'domicilioCap', 'domicilioComune', 'domicilioProvincia', 'domicilioStato', 'corrispondenzaPostale'].forEach(field => {
      const control_name = `abitazione.${field}`;
      if(value) {
        this.iscrizioneForm!.get(control_name)!.enable();
        this.iscrizioneForm!.get(control_name)!.setValidators(Validators.required);
      }else{
        this.iscrizioneForm!.get(control_name)!.setValue(null);
        this.iscrizioneForm!.get('abitazione.corrispondenzaPostale')!.setValue('R');
        this.iscrizioneForm!.get(control_name)!.disable();
        this.iscrizioneForm!.get(control_name)!.clearValidators();
      }
    });
    if(value){
      this.iscrizioneForm!.get('abitazione.domicilioComune')!.setValidators([Validators.required, this.customComuneValidators()]);
      this.iscrizioneForm!.get('abitazione.domicilioProvincia')!.setValidators([Validators.required, this.customProvinciaValidators()]);
      this.iscrizioneForm!.get('abitazione.domicilioStato')!.setValidators([Validators.required, this.customNazioneValidators('stato')]);
    }
    
    if(this.regioneLabel === 'Puglia'){
      this.checkAbitazionePuglia();
    }
  }
  iscrizioneAltroOrdineCheck(value: any){
    const ordine = this.iscrizioneForm!.get('dichiarazioni.regioneAltroOrdine');
    const data = this.iscrizioneForm!.get('dichiarazioni.dataCancellazioneAltroOrdine');
    if(value){
      ordine?.enable();
      ordine?.setValidators([Validators.required, this.customRegioneValidators()]);
      data?.enable();
      data?.setValidators(Validators.required);
    }
    else{
      ordine?.clearValidators();
      ordine?.setValue(null);
      ordine?.disable();
      data?.clearValidators();
      data?.setValue(null);
      data?.disable();
    }
  }
  dipendentePubblicoCheck(value: any){
    const articolo82 = this.iscrizioneForm!.get('dichiarazioni.articolo82');
    const presso = this.iscrizioneForm!.get('dichiarazioni.dipendentePubblicoPresso');
    const ruolo = this.iscrizioneForm!.get('dichiarazioni.dipendentePubblicoRuolo');
    if(value){
      articolo82?.enable();
      articolo82?.setValidators(Validators.required);
      presso?.enable();
      presso?.setValidators(Validators.required);
      ruolo?.enable();
    }
    else{
      articolo82?.clearValidators();
      articolo82?.setValue(null);
      articolo82?.disable();
      presso?.clearValidators();
      presso?.setValue(null);
      presso?.disable();
      ruolo?.setValue(null);
      ruolo?.disable();
    }
  }
  abilitazioneCheck(value: any){
    ['universitaAbilitazione', 'abilitazioneData', 'abilitazioneSessione'].forEach(field => {
      const control_name = `laurea_abilitazione.${field}`;
      if(value) {
        this.iscrizioneForm!.get(control_name)!.enable();
        this.iscrizioneForm!.get(control_name)!.setValidators(Validators.required);
      }else{
        this.iscrizioneForm!.get(control_name)!.setValue('');
        this.iscrizioneForm!.get(control_name)!.disable();
        this.iscrizioneForm!.get(control_name)!.clearValidators();
      }
    });
  }
  articolo82Check(value: any){
    if(value){
      this.iscrizioneForm!.get('dichiarazioni.autorizzazione')!.enable();
      this.iscrizioneForm!.get('dichiarazioni.autorizzazione')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    else{
      this.iscrizioneForm!.get('dichiarazioni.autorizzazione')!.setValue(false);
      this.iscrizioneForm!.get('dichiarazioni.autorizzazione')!.disable();
      this.iscrizioneForm!.get('dichiarazioni.autorizzazione')!.clearValidators();
      this.fileAutorizzazione = {
        tipo: "Autorizzazione",
        descrizione: "Copia dell'Autorizzazione della Libera Professione",
        nomeFile: "",
        file: null,
        chooseLabel: "Upload"
      }
      const index = this.allegati.findIndex(item => item.tipo === "Autorizzazione");
      if(index !== -1){
        this.allegati.splice(index, 1);
      }
    }
    this.iscrizioneForm!.get('dichiarazioni.autorizzazione')?.updateValueAndValidity();
  }
  tesserinoCheck(value: any){
    if(value){
      this.iscrizioneForm!.get('tesserino.allegatoTesserino')!.enable();
      this.iscrizioneForm!.get('tesserino.allegatoTesserino')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    else{
      this.iscrizioneForm!.get('tesserino.allegatoTesserino')!.setValue(false);
      this.iscrizioneForm!.get('tesserino.allegatoTesserino')!.disable();
      this.iscrizioneForm!.get('tesserino.allegatoTesserino')!.clearValidators();
      this.fileTesserino = {
        tipo: "Tesserino",
        descrizione: "Allegare copia del bonifico",
        nomeFile: "",
        file: null,
        chooseLabel: "Upload"
      }
      const index = this.allegati.findIndex(item => item.tipo === "Tesserino");
      if(index !== -1){
        this.allegati.splice(index, 1);
      }
    }
    this.iscrizioneForm!.get('tesserino.allegatoTesserino')?.updateValueAndValidity();
  }

  //Controllo su cittadinanza
  isNotCittadinanzaItaliana(){
    const cittadinanza = this.iscrizioneForm!.get('anagrafica.cittadinanza')?.value;
    return cittadinanza?.nazionalita !== 'Italiana' && cittadinanza?.nazionalita != null;
  }

  //Controllo su residenza o domicilio in Puglia
  checkAbitazionePuglia(){
    if(this.regione == "oprp"){
      this.iscrizioneForm!.get('abitazione.residenzaProvincia')?.valueChanges.subscribe(value => {
      this.residenzaInRegione = this.provinceRegione.includes(value!.nome);
      this.checkResDomPuglia();
      });
      this.iscrizioneForm!.get('abitazione.domicilioProvincia')?.valueChanges.subscribe(value => {
        if(value?.nome != null){
          this.domicilioInRegione = this.provinceRegione.includes(value!.nome);
          this.checkResDomPuglia();
        }
        else{
          this.domicilioInRegione = false;
          this.checkResDomPuglia();
        }
      });
      this.checkResDomPuglia();
      return this.residenzaInRegione || this.domicilioInRegione;
    }
    else{
      return true;
    }
  }
  checkResDomPuglia(){
    if(!this.residenzaInRegione && !this.domicilioInRegione){
      this.iscrizioneForm!.get('abitazione.residenzaProvincia')?.setErrors({ invalid: true });
      this.iscrizioneForm!.get('abitazione.domicilioProvincia')?.setErrors({ invalid: true });
    }
    else{
      this.iscrizioneForm!.get('abitazione.residenzaProvincia')?.setErrors(null);
      this.iscrizioneForm!.get('abitazione.domicilioProvincia')?.setErrors(null);
    }
  }

  //imposta tutti i campi come dirty
  markAllAsDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control?.markAsDirty({ onlySelf: true });
      if (control instanceof FormGroup) {
        this.markAllAsDirty(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((subControl) => {
          if (subControl instanceof FormGroup) {
            this.markAllAsDirty(subControl);
          }
        });
      }
    });
  }

  //validator per controllo true/false
  customTrueValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? null : { notTrue: true };
    };
  }
  //Validator per gli autocomplete
  customComuneValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const corrisponde = this.comuni.some(c => c.nome === control.value.nome);
      return corrisponde ? null : { notValid: true };
    }
  }
  customProvinciaValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const corrisponde = this.province.some(p => p.nome === control.value.nome);
      return corrisponde ? null : { notValid: true };
    }
  }
  customRegioneValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const corrisponde = this.ordini.some(p => p === control.value);
      return corrisponde ? null : { notValid: true };
    }
  }
  customNazioneValidators(tipo: string){
    if(tipo === 'cittadinanza'){
      return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
          return null;
        }
        const corrisponde = this.nazioni.some(n => n.nazionalita === control.value.nazionalita);
        return corrisponde ? null : { notValid: true };
      }
    }
    else{
      return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
          return null;
        }
        const corrisponde = this.nazioni.some(n => n.nome === control.value.nome);
        return corrisponde ? null : { notValid: true };
      }
    }
  }
  customAllegatiValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? null : { notValid: true };
    };
  }

  //gestione dropdown
  dropdownSearch(value: string, options: any[]){
    return options.find(option => option.value === value);
  }

  filtro(event: AutoCompleteCompleteEvent, tipo: string) {
    let query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'comuni'){
      this.filtrati = this.comuni.filter(comune =>
        queryParts.every(part =>
          comune.nome.toLowerCase().includes(part)
        )
      );
    }
    if(tipo === 'province'){
      this.filtrati = this.province.filter(provincia =>
        queryParts.every(part =>
          provincia.nome.toLowerCase().includes(part)
        )
      );
    }
    if(tipo === 'nazioni'){
      this.filtrati = this.nazioni.filter(nazione =>
        queryParts.every(part =>
          nazione.nome.toLowerCase().includes(part)
        )
      );
    }
    if(tipo === 'ordini'){
      this.filtrati = this.ordini.filter(reg =>
        queryParts.every(part =>
          reg.nome.toLowerCase().includes(part)
        )
      );
    }
    if(tipo === 'cittadinanza'){
      this.filtrati = this.nazioni.filter(nazione =>
        queryParts.every(part =>
          nazione.nazionalita.toLowerCase().includes(part)
        )
      );
    }

    this.filtrati.sort((a, b) => a.nome.localeCompare(b.nome));
  }

  buildDate(data_in: Date){
    const day = String(data_in.getDate()).padStart(2, '0');
    const month = String(data_in.getMonth() + 1).padStart(2, '0');
    const year = String(data_in.getFullYear());
    const data = `${year}-${month}-${day}`;
    return data;
  }

  //gestione caricamento file allegati
  onBasicUploadAuto(event: any, tipo: string, fileUpload: any) {
    const fileCaricato = event.files[0];
    let fileItem: any;
    if(tipo === "Permesso_Soggiorno"){
      fileItem = this.filePermessoSoggiorno;
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.setValue(true);
    }
    else if(tipo === "Autorizzazione"){
      fileItem = this.fileAutorizzazione;
      this.iscrizioneForm!.get('dichiarazioni.autorizzazione')!.setValue(true);
    }
    else if(tipo === "Tesserino"){
      fileItem = this.fileTesserino;
      this.iscrizioneForm!.get('tesserino.allegatoTesserino')!.setValue(true);
    }
    else{
      fileItem = this.files.find(file => file.tipo === tipo);
    }
    fileItem.file = fileCaricato;
    fileItem.nomeFile = event.files[0].name;
    fileItem.chooseLabel = "Sovrascrivi";
    const esisteTipo = this.allegati.find(allegato => allegato.tipo === fileItem.tipo);
    if(!esisteTipo){
      this.allegati.push({
        tipo: fileItem.tipo,
        file: fileCaricato
      });
    }
    else{
      esisteTipo.file = fileCaricato;
    }
    this.messageService.add({
      severity: 'info',
      summary: 'Caricato',
      detail: "File caricato correttamente"
    });

    const allFilesUploaded = this.files.every(f => f.nomeFile !== "");
    if(allFilesUploaded){
      this.iscrizioneForm!.get('allegati.allegatiFile')!.setValue(allFilesUploaded);
    }
    //pulizia componente
    fileUpload.clear();
  }
  onBeforeUpload(allegato: any){
    let fileItem: any;
    if(allegato.tipoFile === "Permesso_Soggiorno"){
      fileItem = this.filePermessoSoggiorno;
      this.iscrizioneForm!.get('anagrafica.permessoSoggiorno')!.setValue(true);
    }
    else if(allegato.tipoFile === "Autorizzazione"){
      fileItem = this.fileAutorizzazione;
      this.iscrizioneForm!.get('dichiarazioni.autorizzazione')!.setValue(true);
    }
    else if(allegato.tipoFile === "Tesserino"){
      fileItem = this.fileTesserino;
      this.iscrizioneForm!.get('tesserino.allegatoTesserino')!.setValue(true);
    }
    else{
      fileItem = this.files.find(file => file.tipo === allegato.tipoFile);
    }
    fileItem.nomeFile = allegato.nomeFile;
    fileItem.chooseLabel = "Sovrascrivi";
    this.updateLabel(window.innerWidth);
  }
  visualizzaAllegato(file: any){
    if(file.file !== null){
      const fileUrl = URL.createObjectURL(file.file);
      window.open(fileUrl, '_blank');
    }
    else{
      const id: number = this.iscrittoProva.id!;
      this.master_service.getFileAllegato(id, file.tipo).subscribe({
        next: (fileBlob: Blob) => {
          const fileUrl = URL.createObjectURL(fileBlob);
          window.open(fileUrl, '_blank');
        },
        error: e => {
          this.messageService.add({ severity: 'error', summary: 'Errore', detail: e.error });
        }
      });
    }
  }
  //apri file trattamento dei dati pdf
  openPdfViewer() {
    const pdfUrl = `/assets/documents/trattamento_dati_${this.regione}.pdf`;
    switch(this.regioneLabel){
      case 'Piemonte':
        window.open(pdfUrl, '_blank');
        break;
      default:
        window.open(pdfUrl, '_blank');
        break;
    }
  }

  //scroll allo stepper corretto al click dei tasti Avanti Indietro
  scrollToElement(className: string) {
    setTimeout(() => {
      const element = document.querySelector(`.${className}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 300);
  }

  //gestione tasto Upload in base allo schermo
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateLabel(event.target.innerWidth);
  }
  updateLabel(width: number) {
    const updateChooseLabel = (file: any) => {
      file.chooseLabel = file.nomeFile !== "" ? "Sovrascrivi" : "Upload";
    };
    if (width < 576) {
      this.files.forEach(file => file.chooseLabel = "");
      this.filePermessoSoggiorno.chooseLabel = "";
      this.fileAutorizzazione.chooseLabel = "";
      this.fileTesserino.chooseLabel = "";
    } else {
      this.files.forEach(updateChooseLabel);
      [this.filePermessoSoggiorno, this.fileAutorizzazione, this.fileTesserino].forEach(updateChooseLabel);
    }
  }



  //invia il form a una tabella per le bozze
  generaBozza(){

    const iscrizioneData: any = this.generaDomanda();

    this.invia = false;

    this.confirmationService.confirm({
      header: 'Salva',
        message: 'Confermare la bozza e salvarla?',
        accept: () => {
          this.master_service.postGeneraBozza(iscrizioneData).subscribe({
            next: (response) => {
              this.invioAllegati(iscrizioneData, response.id).subscribe({
                next: (data) => {
                  console.log(data);
                },
                complete: () => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'BOZZA GENERATA',
                    detail: response.message,
                    life: 3000
                  });
                  setTimeout(() => {
                    this.router.navigate(['/pratiche_salvate']);
                  }, 1500);
                },
                error: err => {
                  console.log(err.error);
                }
              });
            },
            error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'ERRORE',
                detail: error.error,
                life: 3000
              });
            }
          });
        },
        reject: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'ANNULLATO',
            detail: 'Salvataggio annullato',
            life: 3000
          });
        }
    });
  }

  //modificare una bozza salvata
  modificaBozza(){

    const iscrizioneData: any = this.generaDomanda();

    this.invia = false;

    this.confirmationService.confirm({
      header: 'Salva',
        message: 'Confermare la modifica della bozza e salvarla?',
        accept: () => {
          this.master_service.putModificaBozza(iscrizioneData, this.iscrittoProva.id).subscribe({
            next: (response: string) => {
              this.invioAllegati(iscrizioneData, this.iscrittoProva.id!).subscribe({
                next: (data) => {
                  console.log(data);
                },
                complete: () => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'BOZZA MODIFICATA',
                    detail: response,
                    life: 3000
                  });
                  setTimeout(() => {
                    this.router.navigate(['/pratiche_salvate']);
                  }, 1500);
                },
                error: err => {
                  console.log(err.error);
                }
              });
            },
            error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'ERRORE',
                detail: error.error,
                life: 3000
              });
            }
          });
        },
        reject: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'ANNULLATO',
            detail: 'Salvataggio annullato',
            life: 3000
          });
        }
    });
  }

  //invia il form per la richiesta di iscrizione
  inviaDomanda(){

    const iscrizioneData: any = this.generaDomanda();

    this.invia = true;

    this.confirmationService.confirm({
      header: 'Invia',
        message: 'Confermare il form e inviare la domanda?',
        accept: () => {
          this.master_service.postInviaDomanda(iscrizioneData).subscribe({
            next: (response) => {
              this.invioAllegati(iscrizioneData, response.id).subscribe({
                next: (data) => {
                  console.log(data);
                },
                complete: () => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'DOMANDA INVIATA',
                    detail: response.message,
                    life: 3000
                  });
                  setTimeout(() => {
                    this.router.navigate(['/pratiche_salvate']);
                  }, 1500);
                },
                error: (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'ERRORE',
                    detail: error.error,
                    life: 3000
                  });
                }
              });
            },
            error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'ERRORE',
                detail: error.error,
                life: 3000
              });
            }
          });
        },
        reject: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'ANNULLATO',
            detail: 'Invio annullato',
            life: 3000
          });
        }
    });
  }

  //invia il form per la richiesta di iscrizione dalla bozza
  inviaDomandaBozza(){

    const iscrizioneData: any = this.generaDomanda();

    this.invia = true;

    this.confirmationService.confirm({
      header: 'Invia',
        message: 'Confermare il form e inviare la domanda?',
        accept: () => {
          this.master_service.putInviaDomandaDaBozza(iscrizioneData, this.iscrittoProva.id).subscribe({
            next: (response: string) => {
              this.invioAllegati(iscrizioneData, this.iscrittoProva.id!).subscribe({
                next: (data) => {
                  console.log(data);
                },
                complete: () => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'DOMANDA INVIATA',
                    detail: response,
                    life: 3000
                  });
                  setTimeout(() => {
                    this.router.navigate(['/pratiche_salvate']);
                  }, 1500);
                },
                error: (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'ERRORE',
                    detail: error.error,
                    life: 3000
                  });
                }
              });
            },
            error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'ERRORE',
                detail: error.error,
                life: 3000
              });
            }
          });
        },
        reject: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'ANNULLATO',
            detail: 'Invio annullato',
            life: 3000
          });
        }
    });
  }

  //invia il form per la richiesta di iscrizione da una domanda già rifiutata
  inviaDomandaRifiutata(){

    const iscrizioneData: any = this.generaDomanda();

    this.invia = true;

    this.confirmationService.confirm({
      header: 'Invia',
        message: 'Confermare il form e inviare la domanda?',
        accept: () => {
          this.master_service.putInviaDomanRifiutata(iscrizioneData, this.iscrittoProva.id).subscribe({
            next: (response: string) => {
              this.invioAllegati(iscrizioneData, this.iscrittoProva.id!).subscribe({
                next: (data) => {
                  console.log(data);
                },
                complete: () => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'DOMANDA INVIATA',
                    detail: response,
                    life: 3000
                  });
                  setTimeout(() => {
                    this.router.navigate(['/pratiche_salvate']);
                  }, 1500);
                },
                error: (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'ERRORE',
                    detail: error.error,
                    life: 3000
                  });
                }
              });
            },
            error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'ERRORE',
                detail: error.error,
                life: 3000
              });
            }
          });
        },
        reject: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'ANNULLATO',
            detail: 'Invio annullato',
            life: 3000
          });
        }
    });
  }

  generaDomanda(){
    const values = this.iscrizioneForm!.value;
    const iscrizione = {
      sezioneAlbo: { id: 2 },

      nome: values.anagrafica?.nome!,
      cognome: values.anagrafica?.cognome!,
      codiceFiscale: values.anagrafica?.codiceFiscale!,
      sesso: values.anagrafica?.sesso?.value!,
      dataNascita: values.anagrafica?.dataNascita! ? this.buildDate(values.anagrafica?.dataNascita) : null,
      comuneNascita: values.anagrafica?.comuneNascita!,
      provinciaNascita: values.anagrafica?.provinciaNascita!,
      cittadinanza: values.anagrafica?.cittadinanza!,

      residenzaComune: values.abitazione?.residenzaComune!,
      residenzaProvincia: values.abitazione?.residenzaProvincia!,
      residenzaIndirizzo: values.abitazione?.residenzaIndirizzo!,
      residenzaFrazione: this.regioneLabel === 'Puglia' ? values.abitazione?.residenzaFrazione! : null,
      residenzaCitofono: this.regioneLabel === 'Piemonte' ? values.abitazione?.residenzaCitofono! : null,
      residenzaCap: values.abitazione?.residenzaCap!,
      residenzaStato: values.abitazione?.residenzaStato!,

      domicilioFlag: values.abitazione?.domicilioFlag!,
      domicilioComune: values.abitazione?.domicilioComune!,
      domicilioProvincia: values.abitazione?.domicilioProvincia!,
      domicilioIndirizzo: values.abitazione?.domicilioIndirizzo!,
      domicilioFrazione: this.regioneLabel === 'Puglia' ? values.abitazione?.domicilioFrazione! : null,
      domicilioCitofono: this.regioneLabel === 'Piemonte' ? values.abitazione?.domicilioCitofono! : null,
      domicilioCap: values.abitazione?.domicilioCap!,
      domicilioStato: values.abitazione?.domicilioStato!,

      corrispondenzaPostale: (this.regioneLabel === 'Piemonte' || this.regioneLabel === 'Puglia')
        ? values.abitazione?.corrispondenzaPostale! : null,

      tipoDocumento: values.documento?.tipoDocumento!,
      numeroDocumento: values.documento?.numeroDocumento!,
      dataRilascioDocumento: values.documento?.dataRilascioDocumento ? this.buildDate(values.documento?.dataRilascioDocumento) : null,
      rilasciatoDa: values.documento?.rilasciatoDa!,

      email: values.contatti?.email!,
      pec: values.contatti?.pec!,
      telefono: values.contatti?.telefono!,
      cellulare: values.contatti?.cellulare!,
      fax: this.regioneLabel === 'Puglia' ? values.contatti?.fax! : null,

      titolo: values.laurea_abilitazione?.titolo!,
      univLaurea: values.laurea_abilitazione?.univLaurea!,
      corsoLaurea: values.laurea_abilitazione?.corsoLaurea!,
      laureaData: values.laurea_abilitazione?.laureaData! ? this.buildDate(values.laurea_abilitazione?.laureaData) : null,

      laureaAbilitante: values.laurea_abilitazione?.laureaAbilitante!,
      universitaAbilitazione: values.laurea_abilitazione?.universitaAbilitazione!,
      abilitazioneSessione: values.laurea_abilitazione?.abilitazioneSessione!,
      abilitazioneData: values.laurea_abilitazione?.abilitazioneData! ? this.buildDate(values.laurea_abilitazione?.abilitazioneData) : null,

      delittiFlag: values.dichiarazioni?.delittiFlag!,
      altriConsigliFlag: values.dichiarazioni?.altriConsigliFlag!,
      iscrizioneAltroOrdineFlag: this.regioneLabel === 'Piemonte' ? values.dichiarazioni?.iscrizioneAltroOrdineFlag! : null,
      regioneAltroOrdine: this.regioneLabel === 'Piemonte' && values.dichiarazioni?.regioneAltroOrdine ? values.dichiarazioni?.regioneAltroOrdine!.nome : null,
      dataCancellazioneAltroOrdine: this.regioneLabel === 'Piemonte' && values.dichiarazioni?.dataCancellazioneAltroOrdine! ? this.buildDate(values.dichiarazioni?.dataCancellazioneAltroOrdine) : null,
      dipendentePubblico: values.dichiarazioni?.dipendentePubblico!,
      dipendentePubblicoPresso: values.dichiarazioni?.dipendentePubblicoPresso!,
      dipendentePubblicoRuolo: values.dichiarazioni?.dipendentePubblicoRuolo!,
      articolo82: values.dichiarazioni?.articolo82!,

      tesserino: this.regioneLabel === 'Puglia' ? values.tesserino?.tesserino! : null,

      trattamentoDatiFlag: values.trattamentoDati?.trattamentoDatiFlag!,
      consensoEmailFlag: this.regioneLabel === 'Piemonte' ? values.trattamentoDati?.consensoEmailFlag! : null,
    }
    
    return iscrizione;
  }

  invioAllegati(iscrizioneData: any, id: number){
    const arr: Observable<any>[] = [];
    for(let all of this.allegati){
      const allegato: Allegato = {
        idPratica: id,
        codiceFiscale: iscrizioneData.codiceFiscale,
        dimensioneFile: all.file.size,
        tipoFile: all.tipo
      }
      arr.push(this.master_service.caricaAllegati(all.file, allegato));
    }
    return merge(...arr)
  }

}