import { ResolveFn } from "@angular/router";
import { DropdownIscrizioniDto, DatiIscrizione, PraticheSalvateDto } from "../models/master-data";
import { inject } from "@angular/core";
import { MasterService } from "./master.service";
import { OAuthService } from "angular-oauth2-oidc";

//resolver dei dropdown per il modulo iscrizione
export const DropdownResolver: ResolveFn<DropdownIscrizioniDto> = (route) => {
  const sezione = route.data['sezione'];
  return inject(MasterService).getDropdownIscrizioni(sezione!);
}

export const BozzaResolver: ResolveFn<DatiIscrizione> = (route) => {
  const id = route.paramMap.get('bozzaId');
  return inject(MasterService).getCaricaPratica(Number(id));
}

export const PraticaResolver: ResolveFn<PraticheSalvateDto> = (route) => {
  const oAuthService = inject(OAuthService);
  const user = oAuthService.getIdentityClaims();
  return inject(MasterService).getPraticheSalvate(user['preferred_username'].toUpperCase());
}