import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../service/master.service';
import { PraticheSalvateDto } from '../../models/master-data';
import { environment } from '../../environment/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-selezione',
  templateUrl: './selezione.component.html',
  styleUrl: './selezione.component.scss'
})
export class SelezioneComponent implements OnInit{

  private router = inject(Router);
  private master_service = inject(MasterService);
  private oAuthService = inject(OAuthService);

  isSezA: boolean = false;
  isSezB: boolean = false;
  isSezAP: boolean = false;
  sezioni: PraticheSalvateDto[] = [];
  actualLabel: any;
  regione = environment.regione;

  isInviata: boolean = false;
  isApprovata: boolean = false;
  
  visible: boolean = false;
  
  //labels e colori bottoni
  buttonLabels: string[] = ['iscrizione sezione a', 'iscrizione sezione b', 'iscrizione sezione a + psicoterapia'];

  ngOnInit() {
    this.loadData();
    this.loadBack();
  }

  loadData(){
    const user = this.oAuthService.getIdentityClaims();
    if(user){
      this.master_service.getPraticheSalvate(user['preferred_username'].toUpperCase()).subscribe((data: any) => {
        this.sezioni = data;
        this.sezioni.forEach((sezione: any) => {
          if(sezione.statoIscrizione === 'Inviata' || sezione.statoIscrizione === 'In Lavorazione'){
            this.isInviata = true;
          }
          else if(sezione.statoIscrizione === 'Approvata'){
            this.isApprovata = true;
          }
          else{
            const temp = sezione.sezioneAlbo;
            if(temp === 'A'){
              this.isSezA = true;
            }
            if(temp === 'B'){
              this.isSezB = true;
            }
            if(temp === 'AP'){
              this.isSezAP = true;
            }
          }
        });
      });
    }
    
  }

  loadBack(){
    const elemento = document.querySelector(".back");
    if (elemento) {
      elemento.classList.add(`${this.regione}`);
    }
  }

  //trasforma le label nell'indirizzo routing
  routerConverter(label: string): string {
    return label.replace(/ /g, '_');
  }

  //https://psicologipuglia.it/wp-content/uploads/2022/01/psicologi-home-video.mp4

  confirm(label: string){
    if(this.isInviata){
      this.visible = true;
      this.actualLabel = { codice: '', sezione: label };;
    }
    else{
      if(label === 'iscrizione sezione a'){
        if(this.isSezA){
          this.visible = true;
          this.actualLabel = { codice: 'A', sezione: label };
        }
        else{
          this.router.navigate([`/${this.routerConverter(label)}`]);
        }
      }
      if(label === 'iscrizione sezione b'){
        if(this.isSezB){
          this.visible = true;
          this.actualLabel = { codice: 'B', sezione: label };
        }
        else{
          this.router.navigate([`/${this.routerConverter(label)}`]);
        }
      }
      if(label === 'iscrizione sezione a + psicoterapia'){
        label = "iscrizione sezione ap";
        if(this.isSezAP){
          this.visible = true;
          this.actualLabel = { codice: 'AP', sezione: label };
        }
        else{
          this.router.navigate([`/${this.routerConverter(label)}`]);
        }
      }
    } 
  }

  vaiAPratiche(){
    const selezioneRoute = true;
    this.router.navigate(['/pratiche_salvate'], { state: { sezione: this.actualLabel.codice, selezioneRoute } });
  }

  nuovaIscrizione(){
    this.router.navigate([`/${this.routerConverter(this.actualLabel.sezione)}`]);
  }

}
