import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MasterService } from '../service/master.service';
import { catchError, map, Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class InviataGuard implements CanActivate {

  private router = inject(Router);
  private master_service = inject(MasterService);
  private oAuthService = inject(OAuthService);

  canActivate(): Observable<boolean> {
    const user = this.oAuthService.getIdentityClaims();
    return this.master_service.verificaPraticaInviata(user['preferred_username'].toUpperCase()).pipe(
      map(data => {
        if(data){
          this.router.navigate(['/access-denied']);
          return false;
        }
        else{
          return true;
        }
      }),
      catchError(() => {
        this.router.navigate(['/access-denied']);
        return [false];
      })
    );
  }

}